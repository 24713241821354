<template>
  <component
    :is="tag"
    ref="Container"
    class="ucrs-navbar__item"
    :class="[
      additionalClasses,
      {
        'ucrs-navbar__item--hasSubnav': item.children,
        'ucrs-navbar__item--isActive': itemIsActive,
      },
    ]"
  >
    <NuxtLink
      ref="menuitem"
      class="ucrs-navbar__link"
      :to="item.href"
      role="menuitem"
      aria-haspopup="true"
      :aria-expanded="`${isExpanded}`"
      tabindex="0"
      @focus.native="isExpanded = true"
      @blur.native="updateExpanded"
    >
      <span v-if="item.icon" class="ucrs-navbar__iconWrapper">
        <SvgIcon
          :name="item.iconheader"
          :class="[
            'ucrs-navbar__icon',
            {
              'ucrs-navbar__icon--active': itemIsActive,
            },
          ]"
        />
      </span>
      <span
        v-dompurify-html="item.text"
        class="ucrs-navbar__label"
        :class="{ 'ucrs-navbar__label--isActive': itemIsActive }"
      ></span>
    </NuxtLink>
    <ul
      v-if="item.children"
      ref="submenu"
      class="ucrs-navbar__subnav"
      :class="{
        'ucrs-navbar__subnav--isVisible z-10': subLinkActiveIndex !== -1,
        'ucrs-navbar__subnav--isFocused': isExpanded,
      }"
      role="menu"
      :aria-label="item.text"
    >
      <template v-for="(subItem, idx) in item.children">
        <li
          v-if="!subItem.onlyMobile"
          :key="idx"
          class="ucrs-navbar__subItem"
          role="none"
        >
          <NuxtLink
            :class="[
              'ucrs-navbar__subLink',
              {
                'ucrs-navbar__subLink--isActive': subLinkActiveIndex === idx,
              },
            ]"
            :to="subItem.href"
            role="menuitem"
            @blur.native="updateExpanded"
          >
            {{ subItem.text }}
          </NuxtLink>
        </li>
      </template>
    </ul>
  </component>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return null
      },
    },
    tag: {
      type: String,
      default: 'div',
    },
    additionalClasses: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isExpanded: false,
    }
  },
  computed: {
    itemIsActive() {
      const { fullPath } = this.$route
      const { href } = this.item

      const pathHref = fullPath.split('/')[1]
      const itemHref = href.replace('/', '')
      return pathHref === itemHref || this.subLinkActiveIndex !== -1
    },

    subLinkActiveIndex() {
      const { fullPath } = this.$route
      const { children } = this.item

      if (!children) {
        return -1
      }

      const subLinkActiveIndex = children.findIndex((it) => {
        const regex = it.href.replace('/', '\\/')

        switch (true) {
          case it.onlyMobile:
          case !fullPath.match(regex):
            return false
        }

        return true
      })

      return subLinkActiveIndex
    },
  },

  mounted() {
    this.$refs.menuitem.$el.addEventListener('mouseenter', () => {
      this.isExpanded = true
    })
    this.$refs.menuitem.$el.addEventListener('mouseleave', () => {
      this.isExpanded = false
    })
  },

  methods: {
    updateExpanded() {
      // setTimeout needed to let document.activeElement update correctly
      setTimeout(() => {
        if (this.$refs.Container.contains(document.activeElement)) return
        this.isExpanded = false
      }, 0)
    },
  },
}
</script>
