<template>
  <nav class="ucrs-navbar" aria-label="Menù di navigazione">
    <div>
      <NuxtLink
        :to="$store.state.auth.loggedIn ? '/area-riservata/dashboard' : '/'"
        class="ucrs-header__logoInner"
        :title="
          $store.state.auth.loggedIn ? 'Dashboard area riservata' : 'Home'
        "
      >
        <!-- FIXME -->
        <!-- <Logo :size="$options.LogoSizes.Large" preload /> -->
        <img
          class="ucsc-logo--large"
          :src="`/assets/img/logo/ucrs-logo.svg`"
          alt="Logo UniCredit RE Services"
          width="260"
          height="40"
        />
      </NuxtLink>
    </div>
    <div class="ucrs-navbar__start">
      <ul
        class="ucrs-navbar__list"
        role="menubar"
        aria-label="Menù di navigazione sezioni"
      >
        <NavItem tag="li" :item="$utilities.navigation.vendo" />
        <NavItem tag="li" :item="$utilities.navigation.cerco" />
        <NavItem tag="li" :item="$utilities.navigation.serviziDocumenti" />
        <NavItem tag="li" :item="$utilities.navigation.contatti" />
        <NavItem tag="li" :item="$utilities.navigation.chisiamo" />
        <SearchRoutes />
      </ul>
    </div>
    <div class="ucrs-navbar__end">
      <AuthLoginIcon v-if="!$store.state.auth.loggedIn" />
      <template v-else>
        <LazyAuth />
        <LazyAuthMobile />
      </template>
    </div>
  </nav>
</template>

<script>
// Libs
import { defineAsyncComponent } from 'vue'

// Static assets
// import LogoSizes from '~/assets/js/logo/sizes'

// Components
import AuthLoginIcon from '~/components/AuthLoginIcon.vue'
// import Logo from '~/components/Logo.vue'
import NavItem from '~/components/NavItem.vue'
import SearchRoutes from '~/components/SearchRoutes.vue'

const LazyAuthMobile = defineAsyncComponent(() =>
  import('~/components/AuthMobile.vue')
)
const LazyAuth = defineAsyncComponent(() => import('~/components/Auth.vue'))

export default {
  components: {
    AuthLoginIcon,
    LazyAuth,
    LazyAuthMobile,
    // Logo,
    NavItem,
    SearchRoutes,
  },

  // LogoSizes,
}
</script>

<style lang="postcss">
@import '~/assets/css/components/nav';
</style>
