import {
  CookieName,
  CookieAcceptValue,
  CookieRejectValue,
  CookieExpirationDays,
} from '~/assets/js/cookie'

export default {
  data() {
    return {
      cookieAccept: CookieAcceptValue,
      cookieReject: CookieRejectValue,
    }
  },

  computed: {
    options() {
      const domain = this.$config.cookieDomain
      const expires = new Date()
      expires.setDate(expires.getDate() + CookieExpirationDays)

      return { domain, expires }
    },
  },

  methods: {
    setCookie(value) {
      this.$cookies.set(CookieName, value, this.options)
      this.$store.dispatch('cookie/setCookie', value)
    },

    getCookie() {
      return this.$cookies.get(CookieName)
    },
  },
}
