var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tag,{ref:"Container",tag:"component",staticClass:"ucrs-navbar__item",class:[
    _vm.additionalClasses,
    {
      'ucrs-navbar__item--hasSubnav': _vm.item.children,
      'ucrs-navbar__item--isActive': _vm.itemIsActive,
    },
  ]},[_c('NuxtLink',{ref:"menuitem",staticClass:"ucrs-navbar__link",attrs:{"to":_vm.item.href,"role":"menuitem","aria-haspopup":"true","aria-expanded":`${_vm.isExpanded}`,"tabindex":"0"},nativeOn:{"focus":function($event){_vm.isExpanded = true},"blur":function($event){return _vm.updateExpanded.apply(null, arguments)}}},[(_vm.item.icon)?_c('span',{staticClass:"ucrs-navbar__iconWrapper"},[_c('SvgIcon',{class:[
          'ucrs-navbar__icon',
          {
            'ucrs-navbar__icon--active': _vm.itemIsActive,
          },
        ],attrs:{"name":_vm.item.iconheader}})],1):_vm._e(),_vm._v(" "),_c('span',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(_vm.item.text),expression:"item.text"}],staticClass:"ucrs-navbar__label",class:{ 'ucrs-navbar__label--isActive': _vm.itemIsActive }})]),_vm._v(" "),(_vm.item.children)?_c('ul',{ref:"submenu",staticClass:"ucrs-navbar__subnav",class:{
      'ucrs-navbar__subnav--isVisible z-10': _vm.subLinkActiveIndex !== -1,
      'ucrs-navbar__subnav--isFocused': _vm.isExpanded,
    },attrs:{"role":"menu","aria-label":_vm.item.text}},[_vm._l((_vm.item.children),function(subItem,idx){return [(!subItem.onlyMobile)?_c('li',{key:idx,staticClass:"ucrs-navbar__subItem",attrs:{"role":"none"}},[_c('NuxtLink',{class:[
            'ucrs-navbar__subLink',
            {
              'ucrs-navbar__subLink--isActive': _vm.subLinkActiveIndex === idx,
            },
          ],attrs:{"to":subItem.href,"role":"menuitem"},nativeOn:{"blur":function($event){return _vm.updateExpanded.apply(null, arguments)}}},[_vm._v("\n          "+_vm._s(subItem.text)+"\n        ")])],1):_vm._e()]})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }