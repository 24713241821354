<template>
  <OAutocomplete
    ref="Autocomplete"
    v-model="searchString"
    placeholder="Cerca nel sito..."
    field="name"
    :data="filteredRoutes"
    name="Cerca nel sito"
    open-on-focus
    clear-on-select
    :root-class="['w-1/2 mt-4']"
    :debounce-typing="500"
    @select="onSelect"
    @focus="$emit('focus', $event)"
    @blur="$emit('blur', $event)"
  >
    <template slot-scope="props">
      <span
        v-dompurify-html="
          $options.highlightSearchedText(props.option.name, searchString)
        "
        class="ucrs-autocomplete__result"
      />
    </template>
    <div slot="empty">Nessun risultato</div>
  </OAutocomplete>
</template>

<script>
import { highlightSearchedText } from '~/assets/js/methods'

const Routes = [
  { name: 'Vendi casa con noi', path: '/vendo' },
  { name: 'Valuta casa', path: '/valuta-casa' },
  {
    name: 'Come fare per vendere casa',
    path: '/news/come-fare/come-vendere-casa-rapidamente/',
  },
  {
    name: 'Documenti per vendere casa',
    path: '/news/come-fare/documenti-vendita-casa/',
  },
  {
    name: 'Come vendere casa velocemente',
    path: '/vendo/come-vendere-casa-velocemente',
  },
  {
    name: 'Documenti per vendere casa nel 2023',
    path: '/vendo/documenti-per-vendere-casa-2023',
  },
  {
    name: 'Il momento di vendere casa',
    path: '/vendo/il-momento-di-vendere-casa',
  },
  {
    name: 'Tre passaggi per vendere casa',
    path: '/vendo/tre-passaggi-per-vendere-casa',
  },
  { name: 'Cerco Casa', path: '/venditaimmobili' },
  { name: 'Cerco Nuove costruzioni', path: '/nuovecostruzioni' },
  { name: 'Cerco Immobili Commerciali', path: '/immobilicommerciali' },
  { name: 'Cerco Immobili di pregio', path: '/immobilidipregio' },
  { name: 'Cerco Proprietà UniCredit', path: '/proprieta-unicredit' },
  { name: 'Servizi', path: '/servizi' },
  { name: 'Servizi e Documenti', path: '/servizi-documenti-casa' },
  { name: 'Magazine UniCredit RE Services', path: '/news' },
  { name: 'Contatto', path: '/contatto' },
  { name: 'Chi siamo', path: '/chisiamo' },
  { name: 'Scopri area riservata', path: '/scopri-area-riservata' },
  { name: 'Scopri app plus', path: '/scopri-app-plus' },
  { name: 'Consigli per vendere casa', path: '/consigli-vendere-casa' },
  { name: 'Acquistare casa', path: '/acquistarecasa' },
]

export default {
  highlightSearchedText,

  data() {
    return {
      searchString: '',
    }
  },
  computed: {
    filteredRoutes() {
      if (!this.searchString || this.searchString.length < 2) return []

      const filteredRoutes = Routes.filter(({ name }) => {
        const lowerName = name.toLowerCase()
        const lowerSearch = this.searchString.trim().toLowerCase()

        return lowerName.includes(lowerSearch)
      })

      const sortedRoutes = filteredRoutes.sort(
        ({ name: nameA }, { name: nameB }) => (nameA > nameB ? 1 : -1)
      )

      return sortedRoutes
    },
  },
  methods: {
    async onSelect(item) {
      // Avoid null item and redundant navigation errors
      if (!item || this.$route.path === item.path) return

      await this.$router.push(item.path)
      this.$emit('select')
    },
  },
}
</script>
