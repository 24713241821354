<template>
  <div class="ucrs-page">
    <Header />
    <LazyMobileNav />
    <main
      :class="[
        'ucrs-main pt-headerHeight lg:pt-28',
        {
          'lg:pt-48': subnavOpen,
        },
      ]"
    >
      <LazyVerificationNotice v-if="arDisabled" />
      <div
        :class="{
          'opacity-50 pointer-events-none': arDisabled,
        }"
      >
        <div id="main-content">
          <Nuxt />
        </div>
      </div>
    </main>
    <Footer />
    <ClientOnly>
      <LazyCookiePanel
        v-if="!cookie"
        :class="{ hidden: !cookiePanel }"
        @open="cookiePanel = true"
        @close="cookiePanel = false"
      />
    </ClientOnly>
  </div>
</template>

<script>
// Libs
import { defineAsyncComponent } from 'vue'
import { mapState, mapMutations } from 'vuex'

// Static assets
import { openSubnavRoutesRegex } from '~/assets/js/mappings'

// Mixins
import Cookie from '~/mixins/cookie'

// Components
import Footer from '~/components/Footer.vue'
import Header from '~/components/Header.vue'

const LazyCookiePanel = defineAsyncComponent(() =>
  import('~/components/CookiePanel.vue')
)
// TODO: Conditionally import when mobile
const LazyMobileNav = () => import('~/components/MobileNav.vue')

// TODO: verify laziness
const LazyVerificationNotice = defineAsyncComponent(() =>
  import('~/components/VerificationNotice.vue')
)

export default {
  components: {
    Footer,
    Header,
    LazyCookiePanel,
    LazyMobileNav,
    LazyVerificationNotice,
  },

  mixins: [Cookie],

  middleware: ['tealium'],

  data() {
    return {
      cookie: !!this.$cookies.get('claw_uci_enable'),
      cookiePanel: false,
    }
  },

  head() {
    const head = {
      bodyAttrs: {
        ...(this.cookiePanel ? { class: 'overflow-hidden' } : {}),
      },
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: this.$config.baseURL + this.$route.path,
        },
      ],
    }

    return head
  },

  computed: {
    ...mapState(['subnavOpen', 'arDisabled']),
  },

  watch: {
    '$route.fullPath': {
      immediate: true,
      handler(fullPath) {
        const subnavOpen = openSubnavRoutesRegex.some((it) => it.test(fullPath))

        this.setSubnavOpen(subnavOpen)
      },
    },
  },

  mounted() {
    // We save the GET parameters gclid and ucid (if they exist) as we will need
    // them in the SellFormCasavo form, they must be sent together with the other
    // parameters generated by the form.
    const { ucid, gclid } = this.$route.query

    if (ucid) {
      sessionStorage.setItem('ucid', ucid)
    }

    if (gclid) {
      sessionStorage.setItem('gclid', gclid)
    }
  },

  methods: {
    ...mapMutations(['setSubnavOpen']),
  },
}
</script>
