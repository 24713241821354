var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ucrs-page"},[_c('Header'),_vm._v(" "),_c('LazyMobileNav'),_vm._v(" "),_c('main',{class:[
      'ucrs-main pt-headerHeight lg:pt-28',
      {
        'lg:pt-48': _vm.subnavOpen,
      },
    ]},[(_vm.arDisabled)?_c('LazyVerificationNotice'):_vm._e(),_vm._v(" "),_c('div',{class:{
        'opacity-50 pointer-events-none': _vm.arDisabled,
      }},[_c('div',{attrs:{"id":"main-content"}},[_c('Nuxt')],1)])],1),_vm._v(" "),_c('Footer'),_vm._v(" "),_c('ClientOnly',[(!_vm.cookie)?_c('LazyCookiePanel',{class:{ hidden: !_vm.cookiePanel },on:{"open":function($event){_vm.cookiePanel = true},"close":function($event){_vm.cookiePanel = false}}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }